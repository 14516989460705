<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { computed, inject } from 'vue';

import { Link, usePage } from '@inertiajs/vue3';
const route = inject('route');
const user = computed(() => usePage().props.user);

const teams = computed(() => Object.values(usePage().props.location_teams));
const current_team_id = computed(() => usePage().props.current_team_id);

const currentTeam = computed(() => teams.value.find((team) => team.id == current_team_id.value));

const otherTeams = computed(() => teams.value.filter((team) => team.id !== current_team_id.value));
</script>

<template>
    <div class="flex gap-4 shrink-0">
        <div class="flex flex-col items-end">
            <Link :href="route('location.profile.index')"> {{ user.first_name }} {{ user.last_name }} </Link>
            <Menu as="div" class="relative" v-slot="{ open }">
                <MenuButton
                    class="w-full bg-transparent rounded-md shadow-sm flex-between focus:outline-none focus:z-10"
                >
                    <div class="w-full text-white border border-transparent shadow-sm flex-between rounded-l-md">
                        <p class="text-base text-white/60">{{ currentTeam?.name ?? '' }}</p>
                        <div class="">
                            <span class="sr-only">Change published status</span>
                            <img
                                src="@/../img/expand-white.svg"
                                class="w-4 h-4 ml-2 text-white duration-100 transform opacity-40"
                                :class="{ 'rotate-180': open }"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </MenuButton>

                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                >
                    <MenuItems
                        class="absolute right-0 mt-2 overflow-hidden origin-top-right bg-white divide-y rounded-md shadow-lg divide-gray-500-100 min-w-75 ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <template v-if="otherTeams.length > 0">
                            <div v-for="team in otherTeams" :key="team.id">
                                <MenuItem v-slot="{ active }">
                                    <Link
                                        :href="route('current-team.update', { team_id: team.id })"
                                        method="put"
                                        as="button"
                                        type="button"
                                        :class="active ? 'bg-orange text-white' : 'text-blue'"
                                        class="w-full py-2 pl-3 pr-4 text-sm text-left min-h-10"
                                    >
                                        {{ team.name }}
                                    </Link>
                                </MenuItem>
                            </div>
                        </template>

                        <div class="text-right w-ful text-pink min-h-10 hover:bg-orange hover:text-white">
                            <Link
                                v-if="$page.props.user"
                                as="button"
                                type="button"
                                method="POST"
                                :href="route('logout')"
                                class="w-full py-2 pl-3 pr-4 text-sm text-left"
                            >
                                {{ $t('Log out') }}
                            </Link>
                        </div>
                    </MenuItems>
                </transition>
            </Menu>
        </div>
        <div
            class="flex items-center justify-center w-12 h-12 overflow-hidden rounded-full text-blue"
            v-if="user.profile_media"
        >
            <Link :href="route('location.profile.index')">
                <img
                    :src="user.profile_media.src"
                    :alt="user.profile_media.alt_text"
                    :srcset="user.profile_media.src_set"
                />
            </Link>
        </div>
        <div class="flex items-center justify-center w-12 h-12 bg-white rounded-full text-blue" v-else>
            <Link :href="route('location.profile.index')">
                <img src="@/../img/icon/user.svg" class="h-5 min-w-5" />
            </Link>
        </div>
    </div>
</template>
